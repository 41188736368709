import LegalAnalytics, { EVENTS } from "../analytics"

import Layout from "../../layout/components/Layout"
import React from "react"
import SEO from "../../layout/components/SEO"
import { documentToComponents } from "../../articles/utils"
import { graphql } from "gatsby"
import { useTrackEventEffect } from "../../common/effects"

function LegalTemplate({ data }) {
  const {
    contentfulLegal: { title, seo, body },
  } = data

  const analytics = LegalAnalytics.legalProperties(title)
  useTrackEventEffect(EVENTS.VIEW_LEGAL, analytics)

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} />
      <div className="w-full md:w-3/5 mx-auto md:mt-10 mb-12 shadow shadow-lg no-underline bg-white p-6">
        <h2 className="text-3xl mt-0 text-gray-600 font-medium leading-snug mb-12">
          {title}
        </h2>
        <div className="mb-8 font-light">{documentToComponents(body)}</div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query legalQuery($slug: String) {
    contentfulLegal(slug: { eq: $slug }) {
      title
      validFrom
      body {
        json
      }
      seo {
        title
        description
      }
    }
  }
`

export default LegalTemplate
