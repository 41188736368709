import LegalEvents from "./events"
import LegalProperties from "./properties"

export { LegalProperties as PROPERTIES, LegalEvents as EVENTS }

export default {
  legalProperties: name => ({
    [LegalProperties.DOCUMENT_NAME]: name,
  }),
}
