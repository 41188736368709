import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const baseDocumentToComponents = {
  [BLOCKS.HEADING_2]: (_, children) => (
    <h2 className="text-3xl mt-4">{children}</h2>
  ),
  [BLOCKS.HEADING_3]: (_, children) => (
    <h3 className="text-2xl mt-4">{children}</h3>
  ),
  [BLOCKS.HEADING_4]: (_, children) => (
    <h4 className="text-xl mt-4">{children}</h4>
  ),
  [BLOCKS.HEADING_5]: (_, children) => (
    <h5 className="text-lg mt-4">{children}</h5>
  ),
  [BLOCKS.PARAGRAPH]: (_, children) => (
    <p className="text-gray-700 pb-6">{children}</p>
  ),
  [INLINES.HYPERLINK]: (node, children) => {
    return (
      <a
        className="border-b-2 border-gray-500 hover:border-indigo-500 hover:text-indigo-500"
        href={node.data.uri}
      >
        {children}
      </a>
    )
  },
  [BLOCKS.LIST_ITEM]: (_, children) => (
    <li className="list-disc">{children}</li>
  ),
  [BLOCKS.OL_LIST]: (_, children) => (
    <ol className="list-outside mx-6">{children}</ol>
  ),
  [BLOCKS.UL_LIST]: (_, children) => (
    <ul className="list-outside mx-6">{children}</ul>
  ),
}

export const documentToComponents = body =>
  documentToReactComponents(body.json, {
    renderNode: {
      ...baseDocumentToComponents,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img alt="" src={node.data.target.fields.file["en-AU"].url} />
      ),
    },
  })
